import React from 'react';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';

const linkItems = [
  {
    path: '/about-us',
    label: 'About Us',
    onClick: 'LINK'
  },
  {
    path: '/contact',
    label: 'Contact Us',
    onClick: 'LINK'
  },
  {
    path: '/privacy',
    label: 'Privacy Policy',
    onClick: 'LINK'
  },
  {
    path: '/terms',
    label: 'Terms and Conditions',
    onClick: 'LINK'
  }
];

function Footer(props) {
  // Determine whether to show footer:
  const { location } = props;
  const hideFooter = Boolean(location.pathname.includes('/signup/'));
  const history = useHistory();

  if (hideFooter) return null;

  return (
    <div className='site-footer'>
      <div className='container text-right footer-links d-flex'>
        <div className='d-block'>
          <div className='site-copyright'>
            <a href='/'>
              <img src='/assets/images/footer-q-83-icon.svg' alt='Q83' />
            </a>
            <p>&copy; 2021 All Rights Reserved</p>
          </div>
        </div>
        <div className='d-block'>
          <div className='footer-links__container'>
            {linkItems.map((item, index) => {
              return (
                <div className='footer-links__item' key={'footer-link-item-' + index}>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      switch (item.onClick) {
                        case 'SCROLL_UP':
                          if (location.pathname !== item.path) {
                            history.push(item.path);
                          }
                          window.scrollTo(0, 0);
                          break;
                        default:
                          history.push(item.path);
                      }
                    }}
                  >
                    {item.label}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        <div className='social-links ml-4'>
          <a href='https://www.facebook.com/Q-83-587743254939259/' target='_blank' rel='noopener noreferrer'>
            <img
              alt=''
              style={{ borderRadius: 5, padding: 0, boxSizing: 'border-box', height: 35 }}
              src='/assets/images/facebook_icon.svg'
            />
          </a>
          <a href='https://www.linkedin.com/company/q-83/' target='_blank' rel='noopener noreferrer'>
            <img
              alt=''
              style={{ backgroundColor: 'white', borderRadius: 5, boxSizing: 'border-box', height: 37 }}
              src='/assets/images/linkedin-white-footer.svg'
            />
          </a>
        </div>
      </div>
      <div className='clearfix'></div>
    </div>
  );
}

export default withRouter(Footer);

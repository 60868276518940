import React, { Component } from 'react';

import LogoLoader from '../components/Loader/LogoLoader';
import Background from '../components/Background';
class FAQs extends Component {
  componentDidMount() {
    window.location.assign('https://support.q-83.com/en/');
  }

  render() {
    return (
      <>
        <Background bgType='bottom' />
        <div className='page-our-team'>
          <h1 className='page-heading text-primary text-center mt-5 mb-5'>Frequently Asked Questions</h1>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            <div
              style={{
                width: '100%',
                maxWidth: '1135px',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
                flexWrap: 'wrap'
              }}
            >
              <LogoLoader />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FAQs;

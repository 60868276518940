import React from "react";

function Background({ bgType = "" }) {
  return (
    <>
      <div className={`website-bg${bgType ? 'website-bg' + bgType : ''}`}>
        <div className={`main-bg`}>
          <div className="bg-top" style={{ backgroundImage: `url(https://media.q-83.com/site/homepage_header.svg)` }} />
          <div
            className="bg-bottom"
            style={{ backgroundImage: `url(https://media.q-83.com/site/homepage_footer.svg)` }}
          />
        </div>
      </div>
    </>
  );
}

export default Background;

const { hostname, protocol } = window.location;

let settings = {
  API_URL: `${protocol}//${hostname}:3333`,
  GA_TOKEN: 'UA-128585569-2',
  PIXEL_CODE: '837489200029679',
  MIXPANEL_TOKEN: '788fb211e906fc4de734d130c8362ff5',
  SMARTLOOK_KEY: '0014ebf7049e0fb3d61aa508dd67d9757521c13c'
};

if (process.env.REACT_APP_DEPLOYMENT === 'staging') {
  settings.API_URL = 'https://api-v2-staging.q-83.com';
  settings.GA_TOKEN = 'UA-128585569-2';
} else if (process.env.REACT_APP_DEPLOYMENT === 'production') {
  settings.API_URL = 'https://api-v2.q-83.com';
  settings.GA_TOKEN = 'UA-128585569-1';
  settings.MIXPANEL_TOKEN = '99e982863fc3e7e7e365112cbfc2aedd';
  settings.SMARTLOOK_KEY = '7d4624fcab8cd77599883dc7a162ef3ccb4bd070';
}

export default settings;

import React from 'react'
import LogoLoader from './LogoLoader'

const LoadingScreen = () => {
  return (
    <>
      <div className="loading-bg">
        <LogoLoader />
      </div>
    </>
  )
}

export default LoadingScreen

import React, { useEffect } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import Header from './components/Header';
import Footer from './components/Footer';

import PageNotFound from './pages/404';
import siteRoutes from './config/Routes';
import { initialiseTracking } from './lib/track';
import withTracker from './withTracker';

function App() {
  useEffect(() => {
    initialiseTracking();
  }, []);

  return (
    <Router>
      <div className='site-layout'>
        <Header />
        <div className='site-layout__body'>
          {/* <div className="container"> */}
          <Switch>
            {siteRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact} component={withTracker((props) => route.main)} />
            ))}
            <Route component={withTracker(PageNotFound)} />
          </Switch>
          {/* </div> */}
        </div>
        <div className='site-layout__footer'>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default withRouter(App);

import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import useloadAtTop from '../hooks/useLoadAtTop';

const TeamMembers = [
  {
    img: 'https://media.q-83.com/site/team/anthony.png',
    name: 'Anthony Richardson',
    title: 'Founder & CEO',
    description:
      'Anthony served as a Mine Warfare Specialist in the Royal Australian Navy before spending a decade building and scaling a suceessful fitness equipment business before founding his own tech company, Q83 in 2018.',
    linkedIn: 'https://www.linkedin.com/in/anthony-richardson-5771a183/'
  },
  {
    img: 'https://media.q-83.com/site/team/jeremy.png',
    name: 'Jeremy Malouf',
    title: 'Lead Developer',
    description:
      'Jeremy is a founding member of Q-83. Starting in digital media advertising Jeremy has had almost a decades experience building commercial apps and websites for a variety of sectors including advertising, non-profit, education and professional services.',
    linkedIn: 'https://www.linkedin.com/in/jeremy-malouf-1740b325/'
  },
  {
    img: 'https://media.q-83.com/site/team/ryley.png',
    name: 'Ryley Woods',
    title: 'Head of Partnerships - APAC',
    description: (
      <>
        Ryley joined Q83 in 2020 having previously helped scale tech start-ups for aquisition in Australia and in the
        UK.
        <br /> Ryley brings more than 13 years of account management and customer success experience within the
        hospitality and technology industries.
      </>
    ),
    linkedIn: 'https://www.linkedin.com/in/ryleywoods/'
  },
  {
    img: 'https://media.q-83.com/site/team/christopher.png',
    name: 'Christopher Henley',
    title: 'Head of Partnerships - UK & Europe',
    description:
      'Chris joined Q83 in 2020 to make a positive difference to an industry he has spent the last 5 years in and is so passionate about. Chris has spent more than 10 years in sales and customer success, more recently playing a key role in the growth of the Influencer Marketing Show in London.',
    linkedIn: 'https://www.linkedin.com/in/henleychristopher/'
  }
];

function AboutUs() {
  useloadAtTop();

  const renderMembers = () => {
    return (
      <div className='row team__list'>
        {TeamMembers.map((member, index) => {
          return (
            <div className=' col-lg-3 col-md-4 col-sm-6 mb-5 member' key={'team-member-' + index}>
              <div className='member__img'>
                <img src={member.img} alt={member.name} />
              </div>
              <h3 className='member__name'>{member.name}</h3>
              <h4 className='member__title'>{member.title}</h4>
              <p>{member.description}</p>
              <div className='member__footer'>
                <a href={member.linkedIn} target='_blank' rel='noopener noreferrer'>
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className='container mt-5 pt-5 page-aboutus'>
      <h2 className='mb-4'>Our Story</h2>
      <p>
        In 2018, Anthony Richardson was scaling his fitness equipment company through the power of influencer marketing.
        Problem was, there was no way he could legitimately qualify the value an influencer would bring to his brand.{' '}
      </p>

      <p>
        It was this gap in the market that saw Anthony go from selling fitness equipment to founding a technology
        company to solve the issues he was facing.{' '}
      </p>

      <p>
        Over two years of research and development Q83 developed and refined tools for talent, their agents, brands, PR,
        influencer marketing and creative agencies. Initially built for social media influencers to have their audience
        insights verified, Q83 evolved to create workflow efficiencies and tools that would help professionalise the
        sharing of value across the whole Influencer marketing industry. This culminated in August 2020 with the launch
        of Kitly and Kitly Business; which now support talent and teams with collaborations all over the world.
      </p>
      <h2 className='mb-4 mt-4'>Our Leadership Team</h2>
      {renderMembers()}
    </div>
  );
}

export default AboutUs;

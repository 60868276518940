import React from 'react'
import './Loader.scss'

const LogoLoader = () => {
  return (
    <div className="loader"></div>
  )
}

export default LogoLoader

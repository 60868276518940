import React, { Component } from 'react';
import Background from '../components/Background';
import LoadingScreen from '../components/Loader/LoadingScreen';
import CompanyLogos from '../components/CompanyLogos';
import { getPageEntry } from '../config/Contentful';

class ContentfulPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.handleGetContent();
  }

  componentDidUpdate() {
    const urlData = document.location.href.split('selectedPlan=');
    if (urlData.length > 1) {
      if (this.state.selectedPlan !== urlData[1]) {
        this.handleSelectPlan(urlData[1]);
      }
    }
  }

  handleGetContent() {
    const { pageKey, match } = this.props;
    let contentId = match && match.params && match.params.id ? match.params.id : pageKey;
    getPageEntry(contentId)
      .then((content) => {
        this.setState({ content });
      })
      .catch((ex) => {
        console.log(ex.message);
      });
  }

  handleSelectPlan = (plan) => {
    if (plan === null) {
      const urlData = document.location.href.split('selectedPlan=');
      if (urlData.length > 1) {
        window.location = document.location.href.split('selectedPlan=')[0];
      }
    }
    this.setState({ selectedPlan: plan });
  };

  render() {
    const { content } = this.state;
    return (
      <>
        <Background />
        {Boolean(content && content.title) ? (
          <>
            <div className='template-content'>
              <div className='row'>
                <div className='col-lg-6 offset-lg-1 pt-5'>
                  <div className='d-lg-inline-block m-auto'>
                    {this.props.heading && this.props.heading}
                    {content.headerImage && <img src={content.headerImage} alt='-' className='img-fluid' />}
                  </div>
                </div>
                <div className='col-lg-4 offset-lg-1 text-lg-white pt-5 mt-5'>
                  <p
                    className='header-text'
                    dangerouslySetInnerHTML={{
                      __html: content.headerText
                    }}
                  />
                  <p className='text-center pt-4'>
                    <ContentLink
                      link={content.headerButtonLink}
                      text={content.headerButtonText}
                      theme={'secondary'}
                      handleSelectPlan={this.handleSelectPlan}
                    />
                  </p>
                </div>
              </div>

              {content.section1Title && (
                <Section
                  title={content.section1Title}
                  titleHighlight={content.section1TitleHighlight}
                  text={content.section1Text}
                  buttonText={content.section1ButtonText}
                  buttonLink={content.section1ButtonLink}
                  image={content.section1Image}
                  images={content.section1Images}
                  handleSelectPlan={this.handleSelectPlan}
                />
              )}

              {content.section2Title && (
                <Section
                  title={content.section2Title}
                  titleHighlight={content.section2TitleHighlight}
                  titlePosition={'left'}
                  text={content.section2Text}
                  buttonText={content.section2ButtonText}
                  buttonLink={content.section2ButtonLink}
                  buttonTheme={'primary'}
                  image={content.section2Image}
                  images={content.section2Images}
                  handleSelectPlan={this.handleSelectPlan}
                />
              )}

              <div style={{ height: 30 }} />

              {content.footerTitle && (
                <div className='text-left mt-5'>
                  <div className='d-inline-block'>
                    <h1 className='page-subheading text-primary pr-5 mb-5'>
                      &nbsp;&nbsp;&nbsp;{content.footerTitle} <br />
                      {content.footerTitleHighlight && (
                        <div className='text-secondary text-left'>{content.footerTitleHighlight}</div>
                      )}
                    </h1>
                  </div>
                </div>
              )}

              <div className='text-center content-footer'>
                <div className='link-container'>
                  <ContentLink
                    link={content.footerButtonLink}
                    text={content.footerButtonText}
                    theme={'secondary'}
                    handleSelectPlan={this.handleSelectPlan}
                  />
                </div>
                <p
                  className='mt-4 text-white'
                  dangerouslySetInnerHTML={{
                    __html: content.footerText
                  }}
                />
                {content.footerImages && <CompanyLogos logos={content.footerImages} />}
              </div>
            </div>
          </>
        ) : (
          <LoadingScreen />
        )}
      </>
    );
  }
}

const ContentLink = ({ link, text, theme, handleSelectPlan }) => {
  if (link && text) {
    if (link.indexOf('/plans/') > -1) {
      let selectedPlan = link.split('/plans/')[1];
      return (
        <button
          onClick={() => {
            handleSelectPlan(selectedPlan);
          }}
          style={{ cursor: 'pointer' }}
          className={`uniform-btn btn btn-${theme ? theme : 'primary'}`}
        >
          {text}
        </button>
      );
    }

    return (
      <a href={link} className={`uniform-btn btn btn-${theme ? theme : 'primary'}`}>
        {text}
      </a>
    );
  }

  return null;
};

const Section = (props) => {
  if (props.image) {
    return <SectionWithImage {...props} />;
  } else {
    return <SectionWithText {...props} />;
  }
};

const SectionWithText = (props) => {
  if (props.titlePosition === 'left') {
    return (
      <>
        <div className='row d-flex align-items-center mb-5'>
          <div className='col-lg-4 offset-lg-1'>
            <h1 className='page-subheading text-secondary text-left'>
              {props.title}
              <span className='text-primary'> {props.titleHighlight}</span>
            </h1>
          </div>
          <div className='col-lg-6 offset-lg-1'>
            <div dangerouslySetInnerHTML={{ __html: props.text }} />
          </div>
        </div>
        <div className='text-center mb-5'>
          <ContentLink
            link={props.buttonLink}
            text={props.buttonText}
            theme={props.buttonTheme || 'primary'}
            handleSelectPlan={props.handleSelectPlan}
          />
        </div>
        {props.images && <CompanyLogos logos={props.images} />}
      </>
    );
  }

  return (
    <>
      <div className='text-right mt-5'>
        <div className='col-lg-10 offset-lg-1'>
          <h1 className='page-subheading text-primary pr-5 mb-5'>
            <div className='text-left'>{props.title}</div>
            <div className='text-secondary'>{props.titleHighlight}</div>
          </h1>
        </div>
      </div>
      <div className='row d-flex align-items-center mb-5 pb-5'>
        <div className='col-lg-6 offset-lg-1' dangerouslySetInnerHTML={{ __html: props.text }} />
        <div className='col-lg-4 offset-lg-1 text-center'>
          <ContentLink
            link={props.buttonLink}
            text={props.buttonText}
            theme={`${props.buttonTheme || 'secondary'} d-inline-block mt-3`}
            handleSelectPlan={props.handleSelectPlan}
          />
        </div>
        {props.images && <CompanyLogos logos={props.images} />}
      </div>
    </>
  );
};

const SectionWithImage = (props) => {
  return (
    <>
      <div className='row d-flex align-items-center mb-5'>
        <div className='col-lg-4 offset-lg-1'>
          <h1 className='page-subheading text-secondary text-left'>
            {props.title}
            <span className='text-primary'> {props.titleHighlight}</span>
          </h1>
          <div dangerouslySetInnerHTML={{ __html: props.text }} />
        </div>
        <div className='col-lg-6 offset-lg-1'>
          <img src={props.image} alt='' className='img-fluid' />
        </div>
        {props.images && <CompanyLogos logos={props.images} />}
      </div>
      <div className='text-center mb-5'>
        <ContentLink
          link={props.buttonLink}
          text={props.buttonText}
          theme={props.buttonTheme || 'primary'}
          handleSelectPlan={props.handleSelectPlan}
        />
      </div>
    </>
  );
};

export default ContentfulPage;

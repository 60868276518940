/**
 * From ReactGA Community Wiki Page https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
 */

import React, { Component } from 'react';
import ReactGA from 'react-ga';
import AppConfig from './config';
import ReactPixel from 'react-facebook-pixel';

import settings from './config';

export default function withTracker(WrappedComponent, options = {}) {
    const trackPage = (page) => {

        if (AppConfig.GA_TOKEN) {
            ReactGA.initialize(AppConfig.GA_TOKEN);
        }

        ReactGA.set({
            page,
            ...options
        });
        var url = new URL(window.location.href);
        var trackParam = url.searchParams.get("track");
        if (trackParam) {
            page = `${page}/track=${trackParam}`;
        }

        ReactGA.pageview(page);

        trackFBPixel(page);
        uploadToLake({ pageName: page, time: Date.now() });
    };

    const HOC = class extends Component {
        componentDidMount() {

            const page = this.props.location.pathname;
            trackPage(page);
        }

        componentWillUpdate(nextProps) {
            const currentPage = this.props.location.pathname;
            const nextPage = nextProps.location.pathname;

            if (currentPage !== nextPage) {
                trackPage(nextPage);
            }
        }

        render() {

            return <WrappedComponent {...this.props} />;
        }
    };

    return HOC;
}

function trackFBPixel(page) {
    ReactPixel.init(settings.PIXEL_CODE);
    ReactPixel.trackCustom(page);
}

async function uploadToLake(eventToSend) {
    if (process.env.REACT_APP_DEPLOYMENT === 'production') {
        var xhr = new XMLHttpRequest();
        xhr.open("POST", "https://a4zvmqj5n3.execute-api.ap-southeast-2.amazonaws.com/1");
        xhr.setRequestHeader("x-api-key", "SI39SjrZUq7U2lXRRyNzO1Ed1gbiN9Op5eduvBDH");
        xhr.send(JSON.stringify(eventToSend));
    }
    else {
        console.log("Skipped loading to datalake in " + process.env.REACT_APP_DEPLOYMENT);
    }
}

import React, { Component } from 'react';

import LogoLoader from '../components/Loader/LogoLoader';
import ReCAPTCHA from 'react-google-recaptcha';

import instance from '../config/API.js';

const errorStyle = { backgroundColor: '#ffeeba' };

const initialState = {
  payload: {},
  loading: false
};

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleRegister = () => {
    let validationError = this.validatePayload(this.state.payload);

    let payloadToSend = Object.assign({}, this.state.payload);

    if (validationError) {
      this.setState({ error: validationError });
    } else {
      this.setState({ loading: true });
      instance
        .post('/contactRequest', { info: payloadToSend, recaptcha_key: this.state.recaptcha_key })
        .then((response) => {
          const { data, error } = response;
          if (error) {
            this.setState({ error: { message: error }, loading: false });
          } else if (data && data.success) {
            this.setState({ success: true, loading: false });
          } else {
            this.setState({
              error: { message: 'Unknown server error!' },
              loading: false
            });
          }
        })
        .catch((ex) => {
          const { error } = ex.response.data;
          if (error) {
            this.setState({ error: { message: error }, loading: false });
          } else {
            console.log('handleLogin EXCEPTION', ex);
            this.setState({ error: ex, loading: false });
          }
        });
    }
  };

  validatePayload(payload) {
    if (!payload.first_name) return { message: 'Please enter your first name', field: 'first_name' };
    if (!payload.last_name) return { message: 'Please enter your last name', field: 'last_name' };
    if (!payload.email) return { message: 'Please enter your email address', field: 'email' };
    if (!payload.subject) return { message: 'Please enter your subject', field: 'subject' };
    if (!payload.message) return { message: 'Please enter your message', field: 'message' };

    return null;
  }

  handleCaptchaSuccess = (recaptcha_key) => {
    this.setState({ recaptcha_key });
  };

  handleChange = (e) => {
    if (e.target && e.target.name) {
      let newPayload = Object.assign({}, this.state.payload, {
        [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
      });
      this.setState({ payload: newPayload });
    }
  };

  render() {
    return (
      <div className='container'>
        <div className='row mt-5 pt-5 page-contact'>
          <div className='col-lg-4 page-contact__title'>
            <h2 className='mb-4 '>Contact Us</h2>
            <p>Got a question? Leave us a message.</p>
          </div>
          <div className='col-lg-7'>
            <fieldset className='page-contact__form'>
              <div className='form-group'>
                <label htmlFor='form-name'>Enter your name</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter your name'
                  id='form-name'
                  name='name'
                  value={this.state.payload.name || ''}
                  onChange={this.handleChange}
                  style={this.state.error && this.state.error.field === 'name' ? errorStyle : null}
                />
              </div>
              {/* <div className='row '>
                <div className='col'>
                  <div className='form-group'>
                    <label htmlFor='form-first-name'>first name</label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='first name'
                      id='form-first-name'
                      name='first_name'
                      value={this.state.payload.first_name || ''}
                      onChange={this.handleChange}
                      style={this.state.error && this.state.error.field === 'first_name' ? errorStyle : null}
                    />
                  </div>
                </div>
                <div className='col'>
                  <div className='form-group'>
                    <label htmlFor='form-last-name'>last name</label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='last name'
                      id='form-last-name'
                      name='last_name'
                      value={this.state.payload.last_name || ''}
                      onChange={this.handleChange}
                      style={this.state.error && this.state.error.field === 'last_name' ? errorStyle : null}
                    />
                  </div>
                </div>
              </div> */}

              <div className='form-group'>
                <label htmlFor='form-email'>email address</label>
                <input
                  type='email'
                  className='form-control'
                  placeholder='Enter your email address'
                  id='form-email'
                  name='email'
                  value={this.state.payload.email || ''}
                  onChange={this.handleChange}
                  style={this.state.error && this.state.error.field === 'email' ? errorStyle : null}
                />
              </div>

              {/* <div className='form-group'>
                <label htmlFor='form-subject'>subject</label>
                <input
                  type='text'
                  className='form-control'
                  id='form-subject'
                  placeholder='subject'
                  name='subject'
                  value={this.state.payload.subject || ''}
                  onChange={this.handleChange}
                  style={this.state.error && this.state.error.field === 'subject' ? errorStyle : null}
                />
              </div> */}

              <div className='form-group'>
                <label htmlFor='form-message'>message</label>
                <textarea
                  className='form-control'
                  id='form-message'
                  placeholder='Enter your message'
                  name='message'
                  value={this.state.payload.message || ''}
                  onChange={this.handleChange}
                  style={this.state.error && this.state.error.field === 'message' ? errorStyle : null}
                  rows={10}
                ></textarea>
              </div>

              <div>
                <ReCAPTCHA sitekey='6Ld0ma8UAAAAAO7rjAUX6CFoaj5SDp3sLMg_Xfjp' onChange={this.handleCaptchaSuccess} />
                <br />
              </div>

              {Boolean(this.state.error && this.state.error.message) && (
                <div className='alert alert-warning'>{this.state.error.message.replace(/^[A-Z_]+:/g, '')}</div>
              )}
              {Boolean(this.state.success === true) && (
                <div className='alert alert-success'>Request sent successfully</div>
              )}
              {this.state.loading === true ? (
                <LogoLoader />
              ) : (
                <div className=' mt-5'>
                  <button className='uniform-btn btn btn-primary' onClick={this.handleRegister}>
                    Submit
                  </button>
                  <div style={{ marginTop: 10 }} />
                </div>
              )}
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUs;

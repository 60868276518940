import React, { Component } from "react";
import LogoLoader from '../components/Loader/LogoLoader'
import { getPageEntry } from "../config/Contentful";

class ContentfulBasic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null
    };
  }

  handleGetContent() {
    const { pageKey, match } = this.props;
    let contentId = (match && match.params && match.params.id) ? match.params.id : pageKey;
    getPageEntry(contentId)
      .then(content => {
        this.setState({ content });
      })
      .catch(ex => {
        console.log(ex.message);
      });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.handleGetContent();
  }

  render() {
    return (
      <>
        {Boolean(this.state.content && this.state.content.title) ? (
          <>
            <div className="template-content">
              <div className="row">
                <div className="col-lg-10 offset-lg-1 pt-5">
                  <div className="d-lg-inline-block m-auto">
                    <h1 className="page-heading pl-5 pr-5 mb-5">
                      {this.state.content.headerTitle}
                    </h1>
                  </div>
                </div>
                <div className="row d-flex align-items-center mb-5 pb-5">
                  <div
                    className="col-lg-10 offset-lg-1"
                    dangerouslySetInnerHTML={{ __html: this.state.content.headerText }}
                  />
                </div>
                <div className="row d-flex align-items-center mb-5 pb-5">
                  <div
                    className="col-lg-10 offset-lg-1"
                    dangerouslySetInnerHTML={{ __html: this.state.content.section1Text }}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
            <LogoLoader />
          )}
      </>
    );
  }
}

export default ContentfulBasic;

import axios from "axios";
import settings from "./index";

const config = {
  domain: settings.API_URL
};

const instance = axios.create({
  baseURL: `${config.domain}`
});

export const getContent = contentPath => {
  return new Promise(async function(resolve, reject) {
    try {
      let response = await instance.get(`content${contentPath}`);
      resolve(response.data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export default instance;

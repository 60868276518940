const navItems = [
  {
    path: '/about-us',
    label: 'About Us',
    onClicked: 'LINK'
  },
  {
    path: '/contact',
    label: 'Contact',
    onClicked: 'LINK'
  }
  // {
  //   path: "/influencer",
  //   label: "influencer",
  //   children: [
  //     {
  //       path: "/influencer",
  //       label: "overview"
  //     },
  //     {
  //       path: "/influencer?selectedPlan=influencer",
  //       label: "plans & pricing"
  //     }
  //   ]
  // },
  // {
  //   path: "/talent-management",
  //   label: "talent management",
  //   children: [
  //     {
  //       path: "/talent-management",
  //       label: "overview"
  //     },
  //     {
  //       path: "/talent-management?selectedPlan=talent_agency",
  //       label: "plans & pricing"
  //     }
  //   ]
  // },
  // {
  //   path: "/brand",
  //   label: "brand",
  //   children: [
  //     {
  //       path: "/brand",
  //       label: "overview"
  //     },
  //     {
  //       path: "/campaign-management",
  //       label: "campaign manager"
  //     },
  //     {
  //       path: "/brand?selectedPlan=brand",
  //       label: "plans & pricing"
  //     }
  //   ]
  // },
  // {
  //   path: "/agency",
  //   label: "agency",
  //   children: [
  //     {
  //       path: "/agency/overview",
  //       label: "overview"
  //     },
  //     {
  //       path: "/campaign-management",
  //       label: "campaign manager"
  //     },
  //     {
  //       path: "/agency?selectedPlan=media_pr",
  //       label: "plans & pricing"
  //     }
  //   ]
  // },
  // {
  //   path: "/our-team",
  //   label: "our team"
  // },
  // {
  //   path: "/blog",
  //   label: "blog"
  // }
];

export default navItems;

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import navItems from '../config/Nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

class Header extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.siteHeader = React.createRef();
    this.state = {
      isMenuOpen: false
    };
    this.headerRef = React.createRef();
  }

  toggleMenu = (open) => {
    this.setState({
      isMenuOpen: open || !this.state.isMenuOpen
    });
  };

  render() {
    const { location } = this.props;
    // Determine whether to show header:
    const hideHeader = Boolean(
      // location.pathname === "/" ||
      location.pathname.includes('/signup/promo/') || location.pathname.includes('/signup/brand/')
    );

    if (!hideHeader) {
      return (
        <div className='site-layout__header' ref={this.headerRef}>
          <header className={'site-header ' + (this.state.isMenuOpen ? 'is-menu-open' : '')} ref={this.siteHeader}>
            <div className='container d-flex'>
              <h2 className='site-header__logo'>
                <NavLink to='/' exact={true}>
                  <img src={'https://q-83.com/assets/images/q83-logo.png'} alt='Q-83 logo' title='Q-83' />
                </NavLink>
              </h2>
              <nav className='site-header__nav'>
                <ul className='site-nav'>
                  {navItems.map((navItem, index) => (
                    <li className={`site-nav__button ${navItem.children ? 'has-children' : ''}`} key={index}>
                      <NavLink
                        to={navItem.path}
                        activeClassName='active'
                        onClick={(e) => {
                          e.preventDefault();
                          this.toggleMenu(false);
                          switch (navItem.onClicked) {
                            case 'SCROLL_TOP':
                              this.headerRef.current.scrollIntoView({ behavior: 'smooth' });
                              break;
                            default:
                              this.props.history.push(navItem.path);
                          }
                        }}
                      >
                        {navItem.label}
                      </NavLink>
                      {navItem.children && (
                        <ul className='site-nav__sub'>
                          {navItem.children.map((childItem, index) => (
                            <li key={index}>
                              <NavLink to={childItem.path} activeClassName='active'>
                                {childItem.label}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </nav>
              {/* {!loginPages && (
                <div className='site-header__buttons'>
                  <NavLink to='/login' activeClassName='' className='btn btn-primary btn-border'>
                    log in
                  </NavLink>
                  <NavLink to='/signup' activeClassName='' className='btn btn-primary'>
                    sign up
                  </NavLink>
                </div>
              )} */}
              <button
                type='button'
                className='btn btn-link d-lg-none btn-menu'
                onClick={() => {
                  this.toggleMenu();
                }}
              >
                <FontAwesomeIcon icon={faBars} />
              </button>
            </div>
          </header>
        </div>
      );
    }
    return <></>;
  }
}

export default withRouter(Header);

import React from 'react';
import { Route } from 'react-router-dom';
import Home from '../pages/Home';
import FAQs from '../pages/FAQs';
import ContentfulPage from '../pages/ContentfulPage';
import ContentfulBasic from '../pages/ContentfulBasic';
import ContactUs from '../pages/ContactUs';
import AboutUs from '../pages/AboutUs';

const siteRoutes = [
  {
    path: '/',
    exact: true,
    main: <Home />
  },
  // {
  //   path: '/',
  //   exact: true,
  //   main: <Landing />
  // },
  {
    path: '/about-us',
    main: <AboutUs />
  },
  {
    path: '/contact',
    main: <ContactUs />
  },
  {
    path: '/preview/:id',
    main: <Route component={ContentfulPage} />
  },
  {
    path: '/preview-basic/:id',
    main: <Route component={ContentfulBasic} />
  },
  {
    path: '/privacy',
    main: <ContentfulBasic pageKey='5B1lYQFsAmnkfA42aVmHUZ' />
  },
  {
    path: '/terms',
    main: <ContentfulBasic pageKey='433F7ye5IHQDSEBDdLAxzY' />
  },
  {
    path: '/faqs',
    main: <FAQs />
  }
];

export default siteRoutes;

import axios from 'axios';
import settings from './index';

const config = {
  domain: settings.API_URL
};

const instance = axios.create({
  baseURL: `${config.domain}`
});

export const getPageEntry = (pageKey) => {
  return new Promise(async function (resolve, reject) {
    try {
      let response = await instance.get(`/content/page/${pageKey}`);
      resolve(response.data);
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getAuthors = () => {
  return new Promise(async function (resolve, reject) {
    try {
      let response = await instance.get(`/content/authors`);
      resolve(response.data);
    } catch (ex) {
      reject(ex);
    }
  });
};

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Hidden from '@material-ui/core/Hidden';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { CountUp } from 'countup.js';

import CompanyLogos from '../components/CompanyLogos';

function Home() {
  const [isStatAnimated, _setIsStatAnimated] = useState(false);

  const isStatAnimatedRef = React.useRef(isStatAnimated);
  const setIsStatAnimated = (data) => {
    isStatAnimatedRef.current = data;
    _setIsStatAnimated(data);
  };

  const statAnimation = () => {
    var AnimationHomeStatActiveUsers = new CountUp('HomeStatActiveUsers', 15000, { suffix: '+' });
    var AnimationHomeStatCountries = new CountUp('HomeStatCountries', 45, { suffix: '+' });
    var AnimationHomeStatCollabs = new CountUp('HomeStatCollabs', 2306, { suffix: '+' });
    var AnimationHomeStatReports = new CountUp('HomeStatReports', 3778, { suffix: '+' });
    var AnimationHomeStatMediaKits = new CountUp('HomeStatMediaKits', 6392, { suffix: '+' });

    AnimationHomeStatActiveUsers.start();
    AnimationHomeStatCountries.start();
    AnimationHomeStatCollabs.start();
    AnimationHomeStatReports.start();
    AnimationHomeStatMediaKits.start();
  };

  useEffect(() => {
    const HomeStatsTop = document.querySelector('#HomeStats').offsetTop;
    window.onscroll = function () {
      var scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
      if (isStatAnimatedRef.current === false && HomeStatsTop <= scrollPosition + window.innerHeight * 0.75) {
        statAnimation();
        setIsStatAnimated(true);
      }
    };
  }, []);

  return (
    <div className='page-home'>
      <div className='page-home__banner'>
        <div className='container'>
          <div className='page-home__banner-bg'>
            <hgroup>
              <h2>
                We’re <b>Q83</b>, an <span>Aussie tech </span> <span>company &nbsp;</span>building products for some of
                the world's leading <span>social media talent,&nbsp;</span>
                <span>their agents,&nbsp;</span>
                <span>brands,&nbsp;</span>
                <span>influencer &nbsp;</span>
                <span> marketing</span> and <span>creative agencies.</span>
              </h2>
              <h3>Fair dinkum.</h3>
            </hgroup>
          </div>
        </div>
      </div>
      <div className='page-home__stats'>
        <div className='container'>
          <div className='stats-container' id='HomeStats'>
            <div>
              <span className='number' id='HomeStatActiveUsers'></span>
              active users
            </div>
            <div>
              <span className='number' id='HomeStatCountries'></span>
              countries
            </div>
            <div>
              <span className='number' id='HomeStatCollabs'></span>
              collabs created
            </div>
            <div>
              <span className='number' id='HomeStatReports'></span>
              reports generated
            </div>
            <div>
              <span className='number' id='HomeStatMediaKits'></span>
              media kits created
            </div>
          </div>
        </div>
      </div>
      <div className='page-home__clients'>
        <div className='container'>
          <h3>Our technology is powering</h3>
          <CompanyLogos
            logos={[
              '/assets/images/news-uk.png',
              '/assets/images/fifth.png',
              '/assets/images/76-agency.png',
              '/assets/images/g-and-g.png',
              '/assets/images/gifta.png',
              '/assets/images/two-palms.png',
              '/assets/images/the-remarkables-group.png'
            ]}
          />
        </div>
      </div>
      <div className='page-home__products'>
        <div className='products__kitly'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 col-lg-5 d-flex align-items-center'>
                <div>
                  <div className='header'>
                    <h6>Our Products</h6>
                    <div>
                      <img src='/assets/images/logo-kitly.png' alt='' className={'kitly-logo'} />
                    </div>
                  </div>
                  <p>Professional tools helping talent pitch, land and manage their next brand collaboration.</p>
                  <p>
                    <a href='https://kitly.co' className='link-learn-more' target='_blank' rel='noopener noreferrer'>
                      Learn more &gt;
                    </a>
                  </p>
                  <p>
                    <a
                      href='https://youtu.be/Ml5AM8_nIJs'
                      className='link-video'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <FontAwesomeIcon icon={faPlayCircle} /> Watch video
                    </a>
                  </p>
                  <p className='link-apps'>
                    <a href='https://apps.apple.com/us/app/id1513292587' target='_blank' rel='noopener noreferrer'>
                      <img src='/assets/images/Apple.svg' alt='' />
                    </a>
                    <a
                      href='https://play.google.com/store/apps/details?id=co.kitly.app'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img src='/assets/images/Android.svg' alt='' />
                    </a>
                  </p>
                </div>
              </div>

              <Hidden xsDown>
                <div className='col-md-6 col-lg-7'>
                  <img src='/assets/images/img-kitly.png' className='img-product' alt='' />
                </div>
              </Hidden>
              <Hidden smUp>
                <div className='col-md-6 col-lg-7' style={{ padding: 0 }}>
                  <img src='/assets/images/kitly_mobile.png' className='img-product' alt='' />
                </div>
              </Hidden>
            </div>
          </div>
        </div>
        <div className='products__kitly-business'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 col-lg-5 d-flex align-items-center'>
                <div>
                  <img className='kb-logo' src='/assets/images/logo-kitly-business.svg' alt='' />
                  <p>
                    Tools to help businesses simplify and professionalise the way they manage & execute their talent
                    relationships.
                  </p>
                  <p>
                    <a
                      href='https://kitlybusiness.co'
                      className='link-learn-more'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Learn more &gt;
                    </a>
                  </p>
                  <p>
                    <a
                      href='https://youtu.be/YnP1RDb1Qy8'
                      className='link-video'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <FontAwesomeIcon icon={faPlayCircle} /> Watch video
                    </a>
                  </p>
                </div>
              </div>

              <Hidden xsDown>
                <div className='col-md-6 col-lg-7'>
                  <img src='/assets/images/img-kitly-business.png' className='img-product' alt='' />
                </div>
              </Hidden>
              <Hidden smUp>
                <div className='col-md-6 col-lg-7' style={{ padding: 0 }}>
                  <img src='/assets/images/kb_mobile.png' className='img-product' alt='' />
                </div>
              </Hidden>
            </div>
          </div>
        </div>
      </div>
      <div className='page-home__testimonials'>
        <div className='container'>
          <h5>
            <span className='testimonials__icon' />
            <br />
            TESTIMONIALS
          </h5>
          <p className='testimonials__text'>
            “Q83 has formed an integral element to both the way we position our business and how we execute on campaigns
            for our brand clients.”
          </p>
          <p className='testimonials__author'>Jess Markwood, The Fifth</p>
        </div>
      </div>
    </div>
  );
}

export default Home;

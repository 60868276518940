import React from 'react';

function CompanyLogos(props) {
  if (props && props.logos) {
    return (
      <>
        <div className='row company-logos'>
          {props.logos.map((logo, index) => (
            <div style={{ margin: 5, backgroundImage: `url('${logo}')` }} key={index} />
          ))}
        </div>
      </>
    );
  }
  return <></>;
}

export default CompanyLogos;

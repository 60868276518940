import ReactGA from 'react-ga';
import Smartlook from 'smartlook-client';
import AppConfig from '../config/index';
import ReactPixel from 'react-facebook-pixel';
import Mixpanel from 'mixpanel-browser';

export function initialiseTracking() {
    initialiseMixpanel();
    initialiseSmartlook();
}

export function trackPageView(pageName, user) {
    initialiseGA(user);
    ReactGA.pageview(pageName);

    ReactPixel.init(AppConfig.PIXEL_CODE);
    ReactPixel.trackCustom(pageName);

    const eventToSend = {
        userId: user ? user._id : null,
        type: user ? user.type : null,
        pageName: pageName,
        time: Date.now()
    }
    uploadToLake(eventToSend);
    trackMixpanel(user, pageName);
}

export function trackEvent(category, actionName, user) {
    initialiseGA(user);

    let eventTrack = {
        category: category,
        action: actionName,
        time: Date.now()
    };

    ReactGA.event(eventTrack);

    ReactPixel.init(AppConfig.PIXEL_CODE);
    ReactPixel.trackCustom(actionName);

    const eventToSend = {
        userId: user ? user._id : null,
        type: user ? user.type : null,
        category: category,
        action: actionName,
        time: Date.now()
    }

    trackMixpanel(user, actionName);
    uploadToLake(eventToSend);
}

export function identifyUser(user) {
    try {
        Smartlook.identify(String(user._id), {
            email: user.email,
            type: user.type
        });
        identifyMixpanel(user);
    } catch (ex) {
        console.log("smartlook.identify EXCEPTION", ex);
    }
}

function initialiseGA(user) {
    if (AppConfig.GA_TOKEN) {
        ReactGA.initialize(AppConfig.GA_TOKEN);
    }

    if (user) {
        let eventSet = { dimension1: user ? user.type : null, userId: user ? user._id : null };
        ReactGA.set(eventSet);
    }
}

async function uploadToLake(eventToSend) {
    if (process.env.REACT_APP_DEPLOYMENT === 'production') {
        var xhr = new XMLHttpRequest();
        xhr.open("POST", "https://a4zvmqj5n3.execute-api.ap-southeast-2.amazonaws.com/1");
        xhr.setRequestHeader("x-api-key", "SI39SjrZUq7U2lXRRyNzO1Ed1gbiN9Op5eduvBDH");
        xhr.send(JSON.stringify(eventToSend));
    }
    else {
        console.log("Skipped loading to datalake in " + process.env.REACT_APP_DEPLOYMENT);
    }
}

function trackMixpanel(user, activity, otherData) {
    try {
        Mixpanel.track(activity, { distinct_id: user._id });
    } catch (error) {
        console.log("Mixpanel track error", error);
    }
}

function identifyMixpanel(user) {
    try {
        Mixpanel.identify(user._id);
        Mixpanel.people.set({
            $email: user.email,
            $distinct_id: user._id,
            $first_name: user.first_name,
            $last_name: user.last_name,
            $created: user.created_at,
            $country_code: user.country,
            $timezone: user.timezone
        });
    } catch (error) {
        console.log("Mixpanel identify error", error);
    }
}

function initialiseMixpanel() {
    try {
        Mixpanel.init(AppConfig.MIXPANEL_TOKEN, {
            protocol: 'https'
        });
    } catch (error) {
        console.log("Mixpanel initialising error", error);
    }
}

function initialiseSmartlook() {
    try {
        Smartlook.init(AppConfig.SMARTLOOK_KEY);
    } catch (ex) {
        console.log("initialiseSmartlook EXCEPTION");
    }
}
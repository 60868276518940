import React from 'react';
import { NavLink } from 'react-router-dom';
import Background from '../components/Background';

const PageNotFound = () => {
  return (
    <>
      <Background bgType='bottom' />
      <h1 className='page-heading text-primary text-center mt-5 mb-5'>404</h1>
      <h5 className='text-center mt-5 mb-5'>Page not found...</h5>
      <div className='text-center'>
        <div className='landing-buttons d-inline-block'>
          <NavLink to='/' className='btn text-primary'>
            Go home
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
